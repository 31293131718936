import {graphql, useStaticQuery} from 'gatsby';

export function useCartPageStaticData() {
    return useStaticQuery(graphql`
        query CartPageQuery {
            wpgraphql {
                pages(where: {name: "cart"}) {
                    nodes {
                        id
                        slug
                        uri
                        seo {
                            ...WPSEOFragment
                        }
                    }
                }
            }
        }
    `);
}
