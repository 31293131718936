import React from 'react';
import classNames from 'classnames';
import {NavLink} from '../../uxui/NavLink';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {CartRemote} from '../../core/models/CartRemote';
import {CheckoutBillingDescriptor} from '../../checkout/components/CheckoutBillingDescriptor';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        boxRowTotal: {
            display: 'flex',
            flexDirection: 'row',
        },
        subtitle: {
            flex: 1,
        },
        padded: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        padded2x: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    }),
);

interface Props {
    cart: CartRemote;
    cartEmpty?: boolean;
}

export const CartSubTotal: React.FC<Props> = ({cart, cartEmpty}) => {
    const classes = useStyles();
    return (
        <Box>
            <Box className={classes.boxRow}>
                <Typography className={classNames(classes.padded, classes.subtitle)} variant="h5">
                    Subtotal
                </Typography>
                <Typography className={classes.padded} variant="h5">
                    {cart.subtotal}
                </Typography>
            </Box>
            {cartEmpty && (
                <Box>
                    <Box className={classes.boxRow}>
                        <Typography className={classNames(classes.padded, classes.subtitle)} variant="h5">
                            Shipping
                        </Typography>
                        <Typography className={classes.padded} variant="h5">
                            {cart.shippingTotal}
                        </Typography>
                    </Box>
                    <Box className={classes.boxRowTotal}>
                        <Typography className={classNames(classes.padded, classes.subtitle)} variant="h5">
                            Total
                        </Typography>
                        <Typography className={classes.padded} variant="h5">
                            {cart.total}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Typography className={classes.padded} variant="body1">
                Orders will be proccesed in USD
            </Typography>
            <CheckoutBillingDescriptor className={classes.padded} />
            {cartEmpty && (
                <NavLink to="/checkout">
                    <Button className={classes.padded} variant="contained" fullWidth color="primary" size="large">
                        Checkout
                    </Button>
                </NavLink>
            )}
            <Divider className={classes.padded2x} />
            <NavLink to="/collections/all">
                <Button className={classes.padded} fullWidth color="primary" size="large">
                    Continue Shopping
                </Button>
            </NavLink>
            <Divider className={classes.padded2x} />
        </Box>
    );
};

CartSubTotal.defaultProps = {
    cartEmpty: false,
};
