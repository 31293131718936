import {MutationOptions, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CustomerInfoFragmentCS} from '../../core/fragments/apollo/CustomerInfoFragmentCS';

const UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
            clientMutationId
            customer {
                ...CustomerInfoFragmentCS
            }
        }
    }
    ${CustomerInfoFragmentCS}
`;

export function useFetchUpdateCustomer(options?: Partial<MutationOptions>) {
    const [fetchUpdateCustomer, {loading}] = useMutation(UPDATE_CUSTOMER, options);
    return {fetchUpdateCustomer, loading};
}
