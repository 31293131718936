import React, {useReducer} from 'react';
import {Layout} from '../modules/layout/containers/Layout';
import SEO from '../modules/layout/containers/SEO';
import {CartPageContent} from '../modules/cart/containers/CartPageContent';
import {CartRemote} from '../modules/core/models/CartRemote';
import {mapCartRemoteToCartWithImages} from '../modules/core/mapper/CartMapper';
import {useProductCatalogThumbS} from '../modules/common/hooks/useProductCatalogThumbS';
import {CartItem} from '../modules/core/models/Cart';
import {useFetchUpdateCartItemQuantities} from '../modules/common/hooks/useFetchUpdateCartItemQuantities';
import {
    computeUpdateCartItemsFromMap,
    UpdatedItemsActionType,
    updatedItemsInitState,
    updatedItemsReducer,
} from '../modules/core/reducer/UpdatedItemsReducer';
import {UpdateItemQuantitiesInput} from '../modules/core/models/mutations/UpdateItemQuantitiesInput';
import {UpdateCustomerInput} from '../modules/core/models/mutations/UpdateCustomerInput';
import {CountriesEnum} from '../modules/core/models/mutations/CountriesEnum';
import {useFetchCartDetailed} from '../modules/common/hooks/useFetchCartDetailed';
import {cartHasShippingOptions} from '../modules/core/util/CartUtil';
import {useFetchUpdateCustomer} from '../modules/common/hooks/useFetchUpdateCustomer';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {useCustomerMutationId} from '../modules/common/hooks/useCustomerMutationId';
import get from 'lodash/get';
import {useCartPageStaticData} from '../modules/common/hooks/useCartPageStaticData';

interface Props {
    location: Location;
}

export const CartPage: React.FC<Props> = ({location}) => {
    const data = useCartPageStaticData();
    const pagesNodes: any = get(data, 'wpgraphql.pages.nodes');
    const pageData = pagesNodes && pagesNodes.length > 0 ? pagesNodes[0] : null;

    const [state, dispatch] = useReducer(updatedItemsReducer, updatedItemsInitState);
    const clientMutationId = useCustomerMutationId();
    const updateCustomerContext = useFetchUpdateCustomer();
    const {fetchUpdateCustomer} = updateCustomerContext;
    const onFetchCartComplete = (data: any) => {
        const cart: CartRemote = data && data.cart;
        if (!cart || cart.isEmpty || cartHasShippingOptions(cart)) return;
        const input: UpdateCustomerInput = {
            clientMutationId,
            billing: {
                country: CountriesEnum.UNITED_STATES_US,
            },
            shipping: {
                country: CountriesEnum.UNITED_STATES_US,
            },
        };
        // console.log('about to updateCustomer...');
        fetchUpdateCustomer({variables: {input}, refetchQueries: ['GetCartDetailed']});
    };
    const {loading, cart} = useFetchCartDetailed({onCompleted: onFetchCartComplete});

    const onFetchUpdateCartItemQuantitiesComplete = () => {
        // console.log('onFetchUpdateCartItemQuantitiesComplete...');
        dispatch({type: UpdatedItemsActionType.RESET, payload: null});
    };

    const fetchUpdateContext = useFetchUpdateCartItemQuantities({onCompleted: onFetchUpdateCartItemQuantitiesComplete});
    const {fetchUpdateItemsQuantities} = fetchUpdateContext;
    // console.log('cart-page:', cart);
    const cartRemote: CartRemote = cart;
    const prodCatalog = useProductCatalogThumbS();
    const isLoading = loading || fetchUpdateContext.loading;

    const cartWithImages = mapCartRemoteToCartWithImages(clientMutationId, cartRemote, prodCatalog);
    // console.log('cartPage: cart:', cart);

    const onApplyCartUpdates = () => {
        if (!state.hasUpdatedItems) return;
        const input: UpdateItemQuantitiesInput = {
            clientMutationId,
            items: computeUpdateCartItemsFromMap(state.updatedItemsMap),
        };
        fetchUpdateItemsQuantities({variables: {input}});
    };

    const onCartItemUpdate = (item: CartItem, newQty: number) => {
        // console.log('onCartItemUpdate....', item, newQty);
        if (!item) return;
        dispatch({type: UpdatedItemsActionType.ADD_ITEM, payload: {item: item, quantity: newQty}});
    };
    const onCartRemoveRequest = (item: CartItem, newQty: number) => {
        // console.log('onCartItemRemoveRequest....', item, newQty);
        if (!item || newQty > 0) return;
        dispatch({type: UpdatedItemsActionType.ADD_ITEM, payload: {item: item, quantity: newQty}});
    };
    // console.log('cartWithImages:', cartWithImages);

    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, pageData && pageData.seo ? pageData.seo : ({} as any));
    return (
        <Layout location={location}>
            <SEO title={`Cart ${title}`} meta={metadata} />
            <CartPageContent
                cart={cartWithImages}
                loading={!!isLoading}
                showUpdate={state.hasUpdatedItems}
                onApplyCartUpdates={onApplyCartUpdates}
                onCartItemUpdate={onCartItemUpdate}
                onCartItemRemoveRequest={onCartRemoveRequest}
            />
        </Layout>
    );
};

export default CartPage;
