import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {ProductImageThumb} from '../../product/components/ProductImageThumb';
import {InputStepper} from '../../uxui/InputStepper';
import {ProductSpecificPriceLabel} from '../../product/components/ProductSpecificPriceLabel';
import {CartItem as CartItemModel} from '../../core/models/Cart';
import {normalizeQty} from '../../core/util/NumberUtil';
import {computeProductSpecificName} from '../../core/util/ProductSpecificUtil';

interface Props {
    item: CartItemModel;
    onQtyUpdate: (item: CartItemModel, qty: number) => void;
    onRemoveRequest: (item: CartItemModel, qty: number) => void;
}

export const CartItem: React.FC<Props> = ({item, onQtyUpdate, onRemoveRequest}) => {
    // console.log('prodSpecific:', prodSpecific, ' prod:', product);
    const [quantity, setQuantity] = useState(item.quantity);
    const {product, productSpecific} = item;
    const handleInputBlur = (e: any) => {
        if (!onRemoveRequest) return;
        const value = normalizeQty(e.target.value) as any;
        if (isNaN(value)) return;
        if (value === 0) {
            return onRemoveRequest(item, value);
        }
        return onQtyUpdate(item, value);
    };
    const handleInputChange = (e: any) => {
        if (!onQtyUpdate) return;
        onQtyUpdate(item, normalizeQty(e.target.value) as any);
    };
    const handleSubstract = () => {
        if (!onQtyUpdate) return;
        const newQty = quantity > 0 ? quantity - 1 : 0;
        setQuantity(newQty);
        if (quantity === 0) {
            onRemoveRequest(item, newQty);
            return;
        }
        onQtyUpdate(item, newQty);
    };
    const handleAdd = () => {
        if (!onQtyUpdate) return;
        const newQty = quantity + 1;
        setQuantity(newQty);
        onQtyUpdate(item, newQty);
    };
    const prodSpecificName = computeProductSpecificName(productSpecific);
    return (
        <Grid container>
            <Grid item xs={4}>
                {product.mainImage && (
                    <ProductImageThumb image={product.mainImage} selected={true} style={{width: 150, height: 150}} />
                )}
            </Grid>
            <Grid item xs={8}>
                <Typography variant="h5" component="h3">
                    {prodSpecificName}
                </Typography>
                <Typography variant="subtitle1">
                    <ProductSpecificPriceLabel product={productSpecific} />
                </Typography>
                <Box>
                    <InputStepper
                        value={quantity}
                        margin="dense"
                        onBlur={handleInputBlur}
                        onChange={handleInputChange}
                        onSubstractClicked={handleSubstract}
                        onAddClicked={handleAdd}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};
