import {MutationOptions, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {CartInfoDetailedFragmentCS} from '../../core/fragments/apollo/CartInfoDetailedFragmentCS';
import {FETCH_CART} from './useFetchCartDetailed';

const UPDATE_ITEMS_QUANTITY = gql`
    mutation UpdateItemQuantities($input: UpdateItemQuantitiesInput!) {
        updateItemQuantities(input: $input) {
            clientMutationId
            cart {
                ...CartInfoDetailedFragmentCS
            }
        }
    }
    ${CartInfoDetailedFragmentCS}
`;

export function useFetchUpdateCartItemQuantities(options?: Partial<MutationOptions>) {
    const [fetchUpdateItemsQuantities, {loading}] = useMutation(UPDATE_ITEMS_QUANTITY, {
        ...options,
        update(cache, {data}) {
            const {
                updateItemQuantities: {cart},
            } = data;
            // console.log('useFetchUpdateCartItemQuantities.update:', cart);
            cache.writeQuery({
                query: FETCH_CART,
                data: {cart: cart},
            });
        },
    });
    return {fetchUpdateItemsQuantities, loading};
}
