import React from 'react';
import {SectionTitle} from '../../uxui/SectionTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {CartSubTotal} from '../components/CartSubTotal';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Cart, CartItem as CartItemModel} from '../../core/models/Cart';
import {CartItem} from '../components/CartItem';
import {LoadingModal} from '../../uxui/LoadingModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemsWithDivider: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            marginRight: theme.spacing(4),
            alignItems: 'stretch',
            alignContent: 'stretch',
        },
        itemsWrapper: {
            flex: 1,
            paddingRight: theme.spacing(3),
        },
        divider: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            // width: 1,
            // minHeight: 100,
        },
        updateWrapper: {
            borderTop: `1px solid ${theme.palette.grey['300']}`,
            paddingTop: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
);

interface Props {
    cart?: Cart;
    loading: boolean;
    onApplyCartUpdates: () => void;
    onCartItemUpdate: (item: CartItemModel, qty: number) => void;
    onCartItemRemoveRequest: (item: CartItemModel, qty: number) => void;
    showUpdate: boolean;
}

export const CartPageContent: React.FC<Props> = ({
    cart,
    loading,
    onApplyCartUpdates,
    onCartItemRemoveRequest,
    onCartItemUpdate,
    showUpdate,
}) => {
    const classes = useStyles();
    const cartItemsEl =
        cart && cart.cartItems && cart.cartItems.length > 0 ? (
            cart.cartItems.map(item => (
                <CartItem
                    key={item.key}
                    item={item}
                    onQtyUpdate={onCartItemUpdate}
                    onRemoveRequest={onCartItemRemoveRequest}
                />
            ))
        ) : (
            <Typography variant="h5" component="h3">
                Your Cart is Empty
            </Typography>
        );
    const updateCartBlock = showUpdate && (
        <Box className={classes.updateWrapper}>
            <Button variant="contained" color="secondary" onClick={onApplyCartUpdates}>
                Update Cart
            </Button>
        </Box>
    );
    const isEmpty = !cart || (cart && cart.isEmpty);

    return (
        <Box>
            <SectionTitle title="Shopping Cart" maxWidth="lg" />
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item md={8} xs={12}>
                        <Box className={classes.itemsWithDivider}>
                            <Box className={classes.itemsWrapper}>
                                {cartItemsEl}
                                {updateCartBlock}
                            </Box>
                            <Divider className={classes.divider} orientation="vertical" />
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {cart && <CartSubTotal cartEmpty={!isEmpty} cart={cart} />}
                    </Grid>
                </Grid>
            </Container>
            <LoadingModal open={loading} />
        </Box>
    );
};
