import {Reducer} from 'react';
import {CartItem} from '../models/Cart';
import _keys from 'lodash/keys';
import {CartItemQuantityInput} from '../models/mutations/UpdateItemQuantitiesInput';

interface UpdatedItem {
    item: CartItem;
    quantity: number;
}
interface UpdatedItemMap {
    [key: string]: UpdatedItem;
}

interface UpdatedItemsReducerState {
    updatedItemsMap: UpdatedItemMap;
    hasUpdatedItems: boolean;
}

interface UpdatedItemsReducerAction {
    type: UpdatedItemsActionType;
    payload: any;
}

export enum UpdatedItemsActionType {
    ADD_ITEM = 'ADD_ITEM',
    RESET = 'RESET',
}

function computeHasUpdatedItems(updatedItemsMap: UpdatedItemMap) {
    const keys = _keys(updatedItemsMap);
    const updatedItem = keys.find(key => {
        const mapItem = updatedItemsMap[key];
        return mapItem && mapItem.quantity !== mapItem.item.quantity;
    });
    return !!updatedItem;
}

export function computeUpdateCartItemsFromMap(updatedItemsMap: UpdatedItemMap): CartItemQuantityInput[] {
    const keys = _keys(updatedItemsMap);
    return keys.map(key => {
        const mapItem = updatedItemsMap[key];
        return {key: key, quantity: mapItem.quantity};
    });
}

export const updatedItemsInitState: UpdatedItemsReducerState = {
    updatedItemsMap: {},
    hasUpdatedItems: false,
};

export const updatedItemsReducer: Reducer<UpdatedItemsReducerState, UpdatedItemsReducerAction> = (state, action) => {
    switch (action.type) {
        case UpdatedItemsActionType.ADD_ITEM:
            const {item, quantity} = action.payload;
            const updatedItemsMap = {...state.updatedItemsMap, ...{[item.key]: {item, quantity}}};
            const hasUpdatedItems = computeHasUpdatedItems(updatedItemsMap);
            return {...state, ...{updatedItemsMap, hasUpdatedItems}};
        case UpdatedItemsActionType.RESET:
            return {...updatedItemsInitState};
        default:
            return state;
    }
};
